import { useFindOneEntity } from "./useFindOneEntity"
import { EntityId, DeploymentEntity } from "@jackfruit/common"

export const useLatestDeployment = (appId: EntityId) => {
  const { data: deployment, isLoading } = useFindOneEntity<DeploymentEntity>(
    "deployments",
    {
      query: {
        appId: appId,
        type: "live",
        status: "complete",
        $limit: 1,
        $sort: { finishedAt: "desc" },
      },
    }
  )

  return { deployment, isLoading }
}
