import { HStack, IconButton, Select, Tooltip } from "@chakra-ui/react"
import { useCustomToast } from "hooks/useCustomToast"
import { useCallback, useState } from "react"
import { FiRefreshCw } from "react-icons/fi"
import { useQueryClient } from "react-query"
import api from "services/api"

interface Props {
  setQuery: any
  query: any
}

const ProductCategoriesTableAction: React.FC<Props> = ({ setQuery, query }) => {
  const toast = useCustomToast()
  const [isSyncing, setIsSyncing] = useState(false)
  const queryClient = useQueryClient()

  const onSynchronize = useCallback(async () => {
    setIsSyncing(true)
    try {
      await api.create("sync-product-categories-with-remote-api", {})
      toast({
        title: "Synchronisation completed.",
        description:
          "Production and staging territories has been successfully synchronized from Autopilot",
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    } catch (error: any) {
      toast({
        title: `Unable to synchronize with remote API`,
        description: error,
        status: "error",
        duration: 10000,
        isClosable: true,
      })
    } finally {
      setIsSyncing(false)
      queryClient.invalidateQueries("product-categories")
    }
  }, [setIsSyncing, toast, queryClient])

  const onSelectChange = useCallback(
    e => {
      const option = e.target.value

      if (option === "all" && query.environment !== undefined) {
        setQuery({
          $or: [
            { environment: "local" },
            { environment: "staging" },
            { environment: "production" },
          ],
        })
        return
      }

      setQuery({
        $or: [{ environment: option }],
      })
    },
    [setQuery, query]
  )
  return (
    <HStack spacing={3} justifyContent="flex-end" alignItems="center">
      <Select variant="filled" disabled={isSyncing} onChange={onSelectChange}>
        <option value="all">All environments</option>
        <option value="local">Local</option>
        <option value="staging">Staging</option>
        <option value="production">Production</option>
      </Select>

      <Tooltip
        label="Sync product categories with remote APIs"
        hasArrow
        arrowSize={15}
      >
        <IconButton
          size="sm"
          aria-label="update"
          icon={<FiRefreshCw />}
          rounded="full"
          colorScheme="primary"
          onClick={onSynchronize}
          disabled={isSyncing}
        />
      </Tooltip>
    </HStack>
  )
}

export default ProductCategoriesTableAction
