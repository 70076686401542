import { Box } from "@chakra-ui/react"
import { BlockEntity, BlockProductFeedEntity } from "@jackfruit/common"
import { useEntity } from "hooks/useEntity"
import { usePatchEntity } from "hooks/usePatchEntity"
import React from "react"
import BlockProductFeedForm from "../forms/BlockProductFeedForm"

interface Props {
  block: BlockEntity
}

const BlockProductFeed: React.FC<Props> = ({ block }) => {
  const { data: productFeedBlock, isLoading } =
    useEntity<BlockProductFeedEntity>("blocks-product-feed", block.childId)

  const { mutate: saveProductFeedBlock, status: patchStatus } =
    usePatchEntity<BlockProductFeedEntity>("blocks-product-feed")

  const isPatching = patchStatus === "loading"

  const onSubmit = (data: any) => {
    saveProductFeedBlock({ id: productFeedBlock.id, ...data })
  }

  if (isLoading) {
    return <Box>Loading...</Box>
  }

  return (
    <BlockProductFeedForm
      entity={productFeedBlock}
      isLoading={isPatching}
      onSubmit={onSubmit}
    />
  )
}

export default BlockProductFeed
