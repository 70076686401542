import { Badge, Box } from "@chakra-ui/react"
import Body from "components/Body"
import { ColumnDefinition } from "components/Table"
import TableSort from "components/TableSort"
import { format } from "date-fns"
import React, { useState } from "react"
import ProductCategoriesTableAction from "./ProductCategoriesTableAction"

interface Props {
  canGoBack: boolean
  title: string
}

const columns: ColumnDefinition[] = [
  {
    header: "id",
    accessor: "id",
  },
  {
    header: "Remote Id",
    accessor: "remoteId",
  },
  {
    header: "Environment",
    accessor: "environment",
    render: entity => {
      switch (entity.environment) {
        case "production":
          return <Badge colorScheme="red">Production</Badge>
        case "staging":
          return <Badge colorScheme="blue">Staging</Badge>
      }
      return <Badge colorScheme="green">Local</Badge>
    },
  },
  { header: "Name", accessor: "name" },
  { header: "Display name", accessor: "displayName" },
  { header: "Parent Category", accessor: "parentCategory" },
  {
    header: "Updated",
    accessor: "updatedAt",
    render: entity => format(new Date(entity.updatedAt), "yyyy/MM/dd"),
  },
  {
    header: "Created",
    accessor: "createdAt",
    render: entity => format(new Date(entity.createdAt), "yyyy/MM/dd"),
  },
]

const defaultQuery = {
  $or: [
    { environment: "local" },
    { environment: "staging" },
    { environment: "production" },
  ],
}

const ProductCategoriesTable: React.FC<Props> = ({ canGoBack, title }) => {
  const [query, setQuery] = useState<any>(defaultQuery)

  return (
    <Body
      canGoBack={canGoBack}
      fullWidth={true}
      padding={0}
      title={title}
      headerActions={
        <ProductCategoriesTableAction setQuery={setQuery} query={query} />
      }
    >
      <Box position="relative">
        <TableSort
          resource="product-categories"
          columns={columns}
          query={query}
          sortByCol="name"
        />
      </Box>
    </Body>
  )
}

export default ProductCategoriesTable
