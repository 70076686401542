import { VStack } from "@chakra-ui/react"
import { BlockType } from "@jackfruit/common"
import { IconType } from "react-icons"
import { BiReceipt } from "react-icons/bi"
import { CgFormatColor } from "react-icons/cg"
import { FiGrid, FiShoppingCart, FiThumbsUp } from "react-icons/fi"
import {
  HiOutlineCode,
  HiOutlineLocationMarker,
  HiOutlineShoppingBag,
} from "react-icons/hi"
import { RiLineChartLine } from "react-icons/ri"
import { RxVideo } from "react-icons/rx"
import { MdOutlineImageSearch, MdRssFeed } from "react-icons/md"
import {
  TbConfetti,
  TbFileUpload,
  TbLayoutBottombar,
  TbLayoutNavbar,
} from "react-icons/tb"
import { TiFlowSwitch } from "react-icons/ti"
import BlockToolBoxGroup from "./BlockToolBoxGroup"

interface Block {
  type: BlockType
  label: string
  icon: IconType
}

interface BlockGroup {
  label: string
  blocks: Block[]
}

export const allBlockGroups: BlockGroup[] = [
  {
    label: "General",
    blocks: [
      { type: "html", label: "Custom HTML", icon: HiOutlineCode },
      {
        type: "graph",
        label: "Graph",
        icon: RiLineChartLine,
      },
      { type: "image-grid", label: "Image Grid", icon: FiGrid },
      {
        type: "order-complete",
        label: "Order Summary",
        icon: BiReceipt,
      },
      { type: "subpage-grid", label: "Page Grid", icon: FiGrid },
      { type: "rich-text", label: "Rich Text", icon: CgFormatColor },
      {
        type: "social-media",
        label: "Social Media",
        icon: FiThumbsUp,
      },
      {
        type: "order-thank-you",
        label: "Thank You",
        icon: TbConfetti,
      },
      {
        type: "video",
        label: "Video",
        icon: RxVideo,
      },
    ],
  },
  {
    label: "Functional",
    blocks: [
      { type: "cart", label: "Cart", icon: FiShoppingCart },
      { type: "checkout", label: "Checkout", icon: HiOutlineShoppingBag },
      { type: "image-upload", label: "File Upload", icon: TbFileUpload },
      { type: "fulfillment", label: "Fulfillment", icon: TiFlowSwitch },
      { type: "product-grid", label: "Product Grid", icon: FiGrid },
      {
        type: "store-search",
        label: "Store Search",
        icon: HiOutlineLocationMarker,
      },
      { type: "template", label: "Template Grid", icon: FiGrid },
      {
        type: "photos-uploaded",
        label: "File Upload Status",
        icon: MdOutlineImageSearch,
      },
      {
        type: "product-feed",
        label: "Product Feed",
        icon: MdRssFeed,
      },
    ],
  },
  {
    label: "Layout",
    blocks: [
      { type: "footer", label: "Footer", icon: TbLayoutBottombar },
      { type: "header", label: "Header", icon: TbLayoutNavbar },
    ],
  },
]

interface Props {
  onAdd: (type: BlockType) => void
}

const BlockToolBox: React.FC<Props> = ({ onAdd }) => {
  return (
    <VStack alignItems="flex-start" w="full" spacing={4}>
      {allBlockGroups.map((group, groupIndex) => (
        <BlockToolBoxGroup
          key={groupIndex}
          label={group.label}
          blocks={group.blocks}
          onAdd={onAdd}
        />
      ))}
    </VStack>
  )
}

export default BlockToolBox
