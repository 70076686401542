import SecondarySidebar, {
  SecondarySidebarNavigationItem,
} from "components/SecondarySidebar/SecondarySidebar"
import LayoutPage from "layouts/LayoutPage"
import React from "react"
import { BiWorld } from "react-icons/bi"
import { FiSettings, FiTag } from "react-icons/fi"
import { RxRocket } from "react-icons/rx"
import { TbPlugConnected } from "react-icons/tb"
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"
import DeploymentsScreen from "./deployments/DeploymentsScreen"
import IntegrationsScreen from "./integrations/IntegrationsScreen"
import ProductCategoriesScreen from "./productCategories/ProductCategoriesScreen"
import TerritoriesScreen from "./territories/TerritoriesScreen"

interface Props {}

const SettingsNavigation: React.FC<Props> = () => {
  const match = useRouteMatch()

  const navigation: SecondarySidebarNavigationItem[] = [
    { label: "Deployments", to: "deployments", icon: RxRocket },
    {
      label: "Integrations",
      to: "integrations",
      icon: TbPlugConnected,
    },
    {
      label: "Product Categories",
      to: "product-categories",
      icon: FiTag,
    },
    {
      label: "Territories",
      to: "territories",
      icon: BiWorld,
    },
  ]

  return (
    <LayoutPage
      secondaryNavigation={
        <SecondarySidebar
          icon={FiSettings}
          title="Settings"
          navigation={navigation}
        />
      }
    >
      <Switch>
        <Redirect exact from={match.url} to={`${match.url}/deployments`} />
        <Route path={`${match.path}/deployments`}>
          <DeploymentsScreen />
        </Route>
        <Route path={`${match.path}/integrations`}>
          <IntegrationsScreen />
        </Route>
        <Route path={`${match.path}/territories`}>
          <TerritoriesScreen />
        </Route>
        <Route path={`${match.path}/product-categories`}>
          <ProductCategoriesScreen />
        </Route>
      </Switch>
    </LayoutPage>
  )
}

export default SettingsNavigation
