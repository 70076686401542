import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
} from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import { EntityFormProps } from "forms/EntityFormProps"
import FormActions from "forms/FormActions"
import React from "react"
import { Controller, useForm } from "react-hook-form"
import AppSeoTextarea from "./AppSeoTextarea"

interface Props extends EntityFormProps<AppEntity> {}

const AppBrandingSeoForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }
  const { handleSubmit, control, register, errors } = useForm<AppEntity>({
    defaultValues,
  })

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <FormSectionHeader>Open Graph</FormSectionHeader>
      <FormControl isInvalid={!!errors.openGraphTitle}>
        <FormLabel>Site Title</FormLabel>
        <InputGroup>
          <Controller
            name="openGraphTitle"
            register={register}
            control={control}
            render={({ name, value, onChange }) => (
              <AppSeoTextarea
                maxLength={60}
                onChange={onChange}
                value={value}
                name={name}
                height={30}
              />
            )}
          />
        </InputGroup>

        <FormErrorMessage>
          {errors.openGraphTitle && errors.openGraphTitle.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.openGraphDescription}>
        <FormLabel>Site description</FormLabel>
        <InputGroup>
          <Controller
            name="openGraphDescription"
            register={register}
            control={control}
            render={({ name, value, onChange }) => (
              <AppSeoTextarea
                maxLength={160}
                onChange={onChange}
                value={value}
                name={name}
                height={30}
              />
            )}
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.openGraphDescription && errors.openGraphDescription.message}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors.openGraphLogo}>
        <FormLabel>Site Logo</FormLabel>
        <InputGroup>
          <Input ref={register()} name="openGraphLogo" type="text" />
        </InputGroup>
        <FormErrorMessage>
          {errors.openGraphLogo && errors.openGraphLogo.message}
        </FormErrorMessage>
      </FormControl>
      <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
    </FormContainer>
  )
}

export default AppBrandingSeoForm
