import Page from "components/Page"
import ProductCategoriesTable from "./ProductCategoriesTable"

interface Props {}
const ProductCategoriesScreen: React.FC<Props> = () => {
  return (
    <Page title="Product Categories">
      <ProductCategoriesTable canGoBack={true} title="All Product Categories" />
    </Page>
  )
}

export default ProductCategoriesScreen
