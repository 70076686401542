import { BlockEntity } from "@jackfruit/common"
import React from "react"
import BlockCart from "./block-types/BlockCart"
import BlockCheckout from "./block-types/BlockCheckout"
import BlockDefault from "./block-types/BlockDefault"
import BlockFooter from "./block-types/BlockFooter"
import BlockFulfillment from "./block-types/BlockFulfillment"
import BlockHeader from "./block-types/BlockHeader"
import BlockHtml from "./block-types/BlockHtml"
import BlockImageGrid from "./block-types/BlockImageGrid"
import BlockImageUpload from "./block-types/BlockImageUpload"
import BlockOrderComplete from "./block-types/BlockOrderComplete"
import BlockOrderThankYou from "./block-types/BlockOrderThankYou"
import BlockPhotosUploaded from "./block-types/BlockPhotosUploaded"
import BlockProductGrid from "./block-types/BlockProductGrid"
import BlockRichText from "./block-types/BlockRichText"
import BlockSocialMedia from "./block-types/BlockSocialMedia"
import BlockStoreSearch from "./block-types/BlockStoreSearch"
import BlockSubpageGrid from "./block-types/BlockSubpageGrid"
import BlockTemplate from "./block-types/BlockTemplate"
import BlockContentWrapper from "./BlockContentWrapper"
import BlockGraph from "./block-types/BlockGraph"
import BlockVideo from "./block-types/BlockVideo"
import BlockImageCarousel from "./block-types/BlockImageCarousel"
import BlockProductFeed from "./block-types/BlockProductFeed"

interface Props {
  block: BlockEntity
  readOnly?: boolean
}

const BlockContent: React.FC<Props> = ({ block, readOnly = false }) => {
  let blockToReturn: React.ReactNode

  switch (block.type) {
    case "header":
      blockToReturn = <BlockHeader block={block} readOnly={readOnly} />
      break
    case "footer":
      blockToReturn = <BlockFooter block={block} readOnly={readOnly} />
      break
    case "image-grid":
      blockToReturn = <BlockImageGrid block={block} />
      break
    case "rich-text":
      blockToReturn = <BlockRichText block={block} />
      break
    case "html":
      blockToReturn = <BlockHtml block={block} />
      break
    case "image-upload":
      blockToReturn = <BlockImageUpload block={block} />
      break
    case "fulfillment":
      blockToReturn = <BlockFulfillment block={block} readOnly={readOnly} />
      break
    case "cart":
      blockToReturn = <BlockCart block={block} />
      break
    case "checkout":
      blockToReturn = <BlockCheckout block={block} readOnly={readOnly} />
      break
    case "store-search":
      blockToReturn = <BlockStoreSearch block={block} />
      break
    case "order-complete":
      blockToReturn = <BlockOrderComplete block={block} />
      break
    case "photos-uploaded":
      blockToReturn = <BlockPhotosUploaded block={block} />
      break
    case "social-media":
      blockToReturn = <BlockSocialMedia block={block} />
      break
    case "subpage-grid":
      blockToReturn = <BlockSubpageGrid block={block} />
      break
    case "order-thank-you":
      blockToReturn = <BlockOrderThankYou block={block} />
      break
    case "template":
      blockToReturn = <BlockTemplate block={block} />
      break
    case "product-grid":
      blockToReturn = <BlockProductGrid block={block} />
      break
    case "graph":
      blockToReturn = <BlockGraph block={block} />
      break
    case "video":
      blockToReturn = <BlockVideo block={block} />
      break
    case "image-carousel":
      blockToReturn = <BlockImageCarousel block={block} />
      break
    case "product-feed":
      blockToReturn = <BlockProductFeed block={block} />
      break
    default:
      blockToReturn = <BlockDefault block={block} />
      break
  }

  return (
    <BlockContentWrapper key={block.id} entity={block}>
      {blockToReturn}
    </BlockContentWrapper>
  )
}

export default BlockContent
