import { Box, Tag, Text } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"

import { useLatestDeployment } from "hooks/useLatestDeployment"
import React from "react"

interface Props {
  app: AppEntity
}

const AppDeployedVersion: React.FC<Props> = ({ app }) => {
  const { lastDeployedAt } = app

  const { deployment, isLoading } = useLatestDeployment(app.id)

  const hasBeenDeployed = Boolean(lastDeployedAt)

  if (isLoading) {
    return <Text>Loading...</Text>
  }
  return (
    <Box as="span" ml={5}>
      {hasBeenDeployed ? (
        <Tag size="sm" colorScheme={"green"}>
          Live: {deployment?.deploymentVersion ?? "Unspecified"}
        </Tag>
      ) : (
        <Tag size="sm" colorScheme={"yellow"}>
          Undeployed
        </Tag>
      )}
    </Box>
  )
}

export default AppDeployedVersion
