import { Text } from "@chakra-ui/react"
import { AppEntity } from "@jackfruit/common"
import { formatDistance } from "date-fns"
import { useLatestDeployment } from "hooks/useLatestDeployment"
import React from "react"

interface Props {
  app: AppEntity
}

const AppCardDeployedVersion: React.FC<Props> = ({ app }) => {
  const { lastDeployedAt } = app

  const { deployment, isLoading } = useLatestDeployment(app.id)

  const hasBeenDeployed = Boolean(lastDeployedAt)

  if (isLoading) {
    return <Text>Loading...</Text>
  }

  return (
    <>
      <Text fontSize="sm" color="gray.500">
        {deployment?.deploymentVersion ?? "Unspecified"}
      </Text>
      <Text fontSize="sm" color="gray.500">
        {hasBeenDeployed
          ? `Last deployed ${formatDistance(
              new Date(lastDeployedAt),
              new Date(),
              {
                includeSeconds: true,
              }
            )} ago`
          : "Never Deployed"}
      </Text>
    </>
  )
}

export default AppCardDeployedVersion
