import { Flex, FormHelperText, Textarea, TextareaProps } from "@chakra-ui/react"

interface Props extends TextareaProps {
  value: string
  height?: number
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}

const AppSeoTextarea: React.FC<Props> = props => {
  const { height = 50, value = "", onChange, maxLength = 60, ...rest } = props
  return (
    <Flex w="full" flexDirection="column">
      <Flex w="full" flexDirection="row">
        <Textarea
          {...rest}
          maxLength={maxLength}
          w="full"
          value={value}
          onChange={onChange}
          h={height}
        />
      </Flex>
      <Flex w="full" flexDirection="row" justifyContent="flex-end">
        <FormHelperText fontSize="xs">
          {value.length}/{maxLength}
        </FormHelperText>
      </Flex>
    </Flex>
  )
}

export default AppSeoTextarea
